
export default {
  components: {
    OrganicHeroLandingPage: () => import('~/components/Organisms/Organic/HeroLandingPage/HeroLandingPage.vue'),
    OrganicModal: () => import('~/components/Molecules/Organic/Modal/Modal.vue'),
    LazyOrganicBegin: () => import('~/components/Organisms/Organic/Begin/Begin.vue'),
    LazyOrganicTimeline: () => import('~/components/Organisms/Organic/Timeline/Timeline.vue'),
    LazyOrganicFormats: () => import('~/components/Organisms/Organic/Formats/Formats.vue'),
    LazyOrganicHelp: () => import('~/components/Organisms/Organic/Help/Help.vue'),
    LazyOrganicProducers: () => import('~/components/Organisms/Organic/Producers/Producers.vue'),
    LazyOrganicFaq: () => import('~/components/Organisms/Organic/Faq/Faq.vue'),
    LazySimpleFooter: () => import('~/components/Organisms/Organic/SimpleFooter/SimpleFooter.vue'),
  },

  computed: {
    selectContent() {
      return this.$t('pages.interestedProducerPaidCampaign')
    },

    isPaidUser() {
      return this.$route.query.utm_campaign === 'bra_br_awar_tra_institucional_search_hotmart'
    },

    hasTopStripe() {
      return true
    },

    isVariationSignupEmbed() {
      return true
    },

    isVariationCampaign() {
      return null
    },

    locale() {
      return this.$i18n.locale
    },

    route() {
      return this.$route
    },
  },
}
